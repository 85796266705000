// import React, { useState, useEffect, useRef } from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import io from "socket.io-client";
// import axios from "axios";
// import { ReactMic } from "react-mic";
// import {
//   faPlus,
//   faMinus,
//   faCaretUp,
//   faCaretDown,
//   faEye,
//   faPlay,
//   faArrowAltCircleLeft,
//   faArrowRotateLeft,
//   faArrowRotateRight,
//   faBatteryThreeQuarters,
//   faPowerOff,
// } from "@fortawesome/free-solid-svg-icons";
// import { Link } from "react-router-dom";
// import AudioReceiver from "./AudioReceiver";
// import { useNavigate } from "react-router-dom";
// const socket = io("https://api.vcaretechnologies.net");

// function Walkietalkie2() {
//   const [isRecording, setIsRecording] = useState(false);
//   const [buttonColor, setButtonColor] = useState("#979797");
//   const [lastAudioIndex, setLastAudioIndex] = useState(-1);
//   const [selectedImage, setSelectedImage] = useState(null);
//   const [audioMessages, setAudioMessages] = useState([]);
//   const [currentGroup, setCurrentGroup] = useState("");
//   const [loading, setLoading] = useState(true);
//   const [longPressTimer, setLongPressTimer] = useState(null);
//   const [refreshInterval, setRefreshInterval] = useState(null);
//   const [selectedFrequency, setSelectedFrequency] = useState("Frequency 1");
//   const [sessionID, setSessionID] = useState(
//     localStorage.getItem("randomSession") || generateRandomString(10)
//   );

//   const navigate = useNavigate();
//   const frequencyOptions = [
//     "Frequency 1",
//     "Frequency 2",
//     "District Officials",
//     "Hospital",
//     "Fire",
//   ];

//   const toggleButton = () => {
//     // Toggle between green and red
//     const newColor = buttonColor === "white" ? " #979797" : "white";
//     setButtonColor(newColor);
//   };

//   function generateRandomString(length) {
//     const characters =
//       "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
//     let result = "";
//     for (let i = 0; i < length; i++) {
//       const randomIndex = Math.floor(Math.random() * characters.length);
//       result += characters.charAt(randomIndex);
//     }
//     return result;
//   }

//   // Set the session ID in localStorage (if it doesn't exist)
//   useEffect(() => {
//     if (!localStorage.getItem("randomSession")) {
//       localStorage.setItem("randomSession", sessionID);
//     }
//   }, [sessionID]);

//   useEffect(() => {
//     localStorage.setItem("selectedFrequency", selectedFrequency);
//   }, [selectedFrequency]);

//   const audioPlayerRef = useRef(null);

//   const startRecording = () => {
//     // Set a timeout to start recording after 10 seconds
//     const timer = setTimeout(() => {
//       setIsRecording(true);
//     }, 1000); // 10 seconds in milliseconds
//     setLongPressTimer(timer);
//   };
//   const stopRecording = () => {
//     // Clear the long press timer
//     if (longPressTimer) {
//       clearTimeout(longPressTimer);
//     }

//     // Stop recording
//     setIsRecording(false);
//   };

//   const onStop = (recordedBlob) => {
//     const audioBlob = recordedBlob.blob;
//     console.log("AudioBlob:", audioBlob);
//     sendAudio(audioBlob);
//   };

//   const onData = (recordedBlob) => {
//     // Do something with recordedBlob if needed
//   };
//   const handleGroupChange = (group) => {
//     setSelectedFrequency(group);
//     console.log("Selected Frequency in handleGroupChange:", group);
//     socket.emit("join-group", group);
//     fetchAudioMessages(group);
//     console.log("Selected Group:", group);
//   };
//   useEffect(() => {
//     console.log("Selected Frequency Updated: ", selectedFrequency);
//     handleGroupChange(selectedFrequency);
//   }, [selectedFrequency]);
//   console.log("sjshs==>", selectedFrequency);

//   const sendAudio = async (audioBlob) => {
//     try {
//       if (audioBlob) {
//         console.log("Selected Frequency2222:", selectedFrequency);
//         socket.emit("send-data", {
//           data: audioBlob,
//           group: selectedFrequency,
//           session: sessionID,
//           type: 1,
//         });
//         console.log("Sending audio with group:", selectedFrequency);
//         console.log("Sending sesssion with id:", sessionID);
//       } else {
//         console.log("No audio to send");
//       }
//     } catch (error) {
//       console.error("Error sending audio:", error);
//     }
//   };

//   const fetchAudioMessages = async (selectedFrequency) => {
//     try {
//       const response = await axios.get(
//         `https://api.vcaretechnologies.net/api/audio-messages?group=${selectedFrequency}`
//       );
//       setAudioMessages(response.data.response);

//       console.log("res====>", response.data.response);
//       setLoading(false);
//     } catch (error) {
//       console.log("error===>", error);
//     }
//   };

//   useEffect(() => {
//     // fetchAudioMessages();
//     if (selectedFrequency !== currentGroup) {
//       setCurrentGroup(selectedFrequency);

//       if (audioPlayerRef.current) {
//         audioPlayerRef.current.audio.current.pause();
//       }
//     }
//     socket.on("received-audio", (audioPath) => {
//       //   setAudioMessages((prevMessages) => [
//       //     ...prevMessages,
//       //     { audio_url: audioPath },
//       //   ]);
//       // });
//       const newAudioMessage = {
//         audio_url: audioPath,
//         group_name: selectedFrequency,
//       };
//       setAudioMessages((prevMessages) => {
//         // Update the last audio index when a new audio message arrives
//         setLastAudioIndex(prevMessages.length);
//         return [...prevMessages, newAudioMessage];
//       });
//     });

//     setCurrentGroup(selectedFrequency);

//     return () => {
//       socket.off("received-audio");
//     };
//   }, [selectedFrequency, currentGroup]);

//   //   useEffect(() => {
//   //     if (refreshInterval) {
//   //       clearInterval(refreshInterval); // Clear the previous interval
//   //     }

//   //     // Start a new interval that fetches audio messages every 2 seconds
//   //     const newInterval = setInterval(fetchAudioMessages, 1000);

//   //     setRefreshInterval(newInterval); // Store the new interval in state

//   //     return () => {
//   //       if (newInterval) {
//   //         clearInterval(newInterval); // Clear the interval when the component unmounts
//   //       }
//   //     };
//   //   }, [selectedFrequency]);

//   // Function to handle increasing the frequency
//   const increaseFrequency = () => {
//     const currentIndex = frequencyOptions.indexOf(selectedFrequency);
//     if (currentIndex > 0) {
//       setSelectedFrequency(frequencyOptions[currentIndex - 1]);
//     }
//   };

//   // Function to handle decreasing the frequency
//   const decreaseFrequency = () => {
//     const currentIndex = frequencyOptions.indexOf(selectedFrequency);
//     if (currentIndex < frequencyOptions.length - 1) {
//       setSelectedFrequency(frequencyOptions[currentIndex + 1]);
//     }
//   };
//   const handleFileUpload = async () => {
//     // Create an input element
//     const inputElement = document.createElement("input");
//     inputElement.type = "file";

//     // Add an event listener to handle the file selection
//     inputElement.addEventListener("change", async (event) => {
//       const selectedImage = event.target.files[0]; // Get the selected file

//       if (selectedImage) {
//         // Create a FormData object to send the file to the server
//         const formData = new FormData();
//         formData.append("image", selectedImage);
//         formData.append("type", "3");
//         formData.append("session", sessionID);
//         formData.append("group", selectedFrequency);

//         // Send the file to your server using the Fetch API with Axios
//         try {
//           await axios.post("http://localhost:5000/api/upload-image", formData, {
//             headers: {
//               "Content-Type": "multipart/form-data",
//             },
//           });
//           navigate("/chat");
//           console.log("Image sent successfully");
//         } catch (error) {
//           console.error("Error sending the image:", error);
//         }
//       } else {
//         console.log("No image to send");
//       }
//     });

//     // Trigger the file input dialog
//     inputElement.click();
//   };
//   const handleThemeChange = (event) => {
//     const selectedTheme = event.target.value;

//     if (selectedTheme === "Theme 1") {
//       navigate("/"); // Use the navigate function to redirect
//     }
//   };

//   return (
//     <div className="walkietalkie-2">
//       <div>
//         <select class="form-control " onChange={handleThemeChange}>
//           <option value="Theme 2">Theme 2 </option>
//           <option value="Theme 1">Theme 1</option>
//         </select>
//       </div>
//       <section className="walkie_talkie_section2">
//         <div className="main_mobile_part2">
//           <div className="mobile_bg_box2">
//             <div className="mobile_display2">
//               <button
//                 className="power_btn2 "
//                 style={{ color: buttonColor }}
//                 onClick={toggleButton}
//               >
//                 <FontAwesomeIcon icon={faPowerOff} />
//               </button>
//               <select
//                 className="form-control transparent-background inner_select"
//                 value={selectedFrequency}
//                 onChange={(e) => handleGroupChange(e.target.value)}
//                 // onChange={handleGroupChange}
//               >
//                 {/* Map through frequencyOptions and generate dropdown options */}
//                 {frequencyOptions.map((frequency) => (
//                   <option key={frequency} value={frequency}>
//                     {frequency}
//                   </option>
//                 ))}
//               </select>
//               <div className="battery_img2">
//                 <FontAwesomeIcon
//                   icon={faBatteryThreeQuarters}
//                   classNameName="battery_icon2"
//                 />
//               </div>
//               <div className="pulse_box2">
//                 <ReactMic
//                   record={isRecording}
//                   onData={onData}
//                   onStop={onStop}
//                   className="sound-wave2"
//                   mimeType="audio/webm"
//                 />
//               </div>
//               <button className="btn play_button2">
//                 <FontAwesomeIcon icon={faPlay} />
//               </button>
//               <a href="/view" className="btn view_button2">
//                 <FontAwesomeIcon icon={faArrowRotateRight} />
//               </a>
//             </div>
//             <div className="mobile_body2">
//               <span className="chat_btn2">
//                 <Link to="/chat">
//                   <button>Chat</button>
//                 </Link>
//               </span>

//               <span className="upload_btn2">
//                 <button onClick={handleFileUpload}>Camera</button>
//               </span>
//               <div className="ch_box2">
//                 <button className="up2" onClick={increaseFrequency}>
//                   <FontAwesomeIcon icon={faCaretUp} />
//                 </button>
//                 <span>CH.</span>
//                 <button className="down2" onClick={decreaseFrequency}>
//                   <FontAwesomeIcon icon={faCaretDown} />
//                 </button>
//               </div>
//               <div className="vol_box2">
//                 <button className="up2">
//                   <FontAwesomeIcon icon={faPlus} />
//                 </button>
//                 <span>Vol</span>
//                 <button className="down2">
//                   <FontAwesomeIcon icon={faMinus} />
//                 </button>
//               </div>
//               <button
//                 className="mic_img2"
//                 onMouseDown={startRecording}
//                 onMouseUp={stopRecording}
//                 onTouchStart={startRecording}
//                 onTouchEnd={stopRecording}
//                 disabled={buttonColor === "#979797"}
//               >
//                 <svg
//                   xmlns="http://www.w3.org/2000/svg"
//                   width="175"
//                   height="175"
//                   viewBox="0 0 175 175"
//                   fill="none"
//                 >
//                   <circle cx="87.5" cy="87.5" r="87.5" fill="black" />
//                   <g filter="url(#filter0_ii_614_1206)">
//                     <circle cx="87.5" cy="87.5" r="80" fill="#35383F" />
//                   </g>
//                   <path
//                     d="M27.8525 121.938C26.1187 122.939 23.8929 122.348 22.9798 120.567C18.3258 111.486 15.6348 101.512 15.0994 91.2944C14.5639 81.0772 16.1976 70.8764 19.8769 61.3588C20.5987 59.4914 22.7506 58.6717 24.5796 59.486C26.4085 60.3003 27.2209 62.44 26.5094 64.3114C23.2895 72.7804 21.8638 81.8405 22.3394 90.9149C22.815 99.9894 25.1799 108.851 29.2674 116.937C30.1706 118.724 29.5863 120.936 27.8525 121.938Z"
//                     fill="#979797"
//                     className="box_shadow2"
//                   />
//                   <path
//                     d="M147.147 121.938C148.881 122.939 151.107 122.348 152.02 120.567C156.674 111.486 159.365 101.512 159.901 91.2944C160.436 81.0772 158.802 70.8764 155.123 61.3588C154.401 59.4914 152.249 58.6717 150.42 59.486C148.591 60.3003 147.779 62.44 148.491 64.3114C151.711 72.7804 153.136 81.8405 152.661 90.9149C152.185 99.9894 149.82 108.851 145.733 116.937C144.829 118.724 145.414 120.936 147.147 121.938Z"
//                     fill="#979797"
//                     className="box_shadow2"
//                   />
//                   <g filter="url(#filter1_ii_614_1206)">
//                     <circle cx="87.5" cy="87.5001" r="60" fill="#2E3136" />
//                   </g>
//                   <path
//                     d="M87.5 91.4376C90.8012 91.4376 93.4766 88.7939 93.4766 85.5314V77.6564C93.4766 74.3939 90.8012 71.7501 87.5 71.7501C84.1988 71.7501 81.5234 74.3939 81.5234 77.6564V85.5314C81.5234 88.7939 84.1988 91.4376 87.5 91.4376ZM99.1016 85.4611C99.1016 85.3064 98.975 85.1798 98.8203 85.1798H96.7109C96.5562 85.1798 96.4297 85.3064 96.4297 85.4611C96.4297 90.3935 92.4324 94.3907 87.5 94.3907C82.5676 94.3907 78.5703 90.3935 78.5703 85.4611C78.5703 85.3064 78.4437 85.1798 78.2891 85.1798H76.1797C76.025 85.1798 75.8984 85.3064 75.8984 85.4611C75.8984 91.3919 80.3492 96.2857 86.0938 96.9782V100.578H80.9855C80.5039 100.578 80.1172 101.081 80.1172 101.703V102.969C80.1172 103.124 80.2156 103.25 80.3352 103.25H94.6648C94.7844 103.25 94.8828 103.124 94.8828 102.969V101.703C94.8828 101.081 94.4961 100.578 94.0145 100.578H88.7656V96.9958C94.577 96.363 99.1016 91.4411 99.1016 85.4611Z"
//                     fill="#979797"
//                     className="box_shadow2"
//                   />
//                   <defs>
//                     <filter
//                       id="filter0_ii_614_1206"
//                       x="5.5"
//                       y="5.5"
//                       width="164"
//                       height="164"
//                       filterUnits="userSpaceOnUse"
//                       color-interpolation-filters="sRGB"
//                     >
//                       <feFlood flood-opacity="0" result="BackgroundImageFix" />
//                       <feBlend
//                         mode="normal"
//                         in="SourceGraphic"
//                         in2="BackgroundImageFix"
//                         result="shape"
//                       />
//                       <feColorMatrix
//                         in="SourceAlpha"
//                         type="matrix"
//                         values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
//                         result="hardAlpha"
//                       />
//                       <feOffset dx="2" dy="2" />
//                       <feGaussianBlur stdDeviation="2" />
//                       <feComposite
//                         in2="hardAlpha"
//                         operator="arithmetic"
//                         k2="-1"
//                         k3="1"
//                       />
//                       <feColorMatrix
//                         type="matrix"
//                         values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.12 0"
//                       />
//                       <feBlend
//                         mode="normal"
//                         in2="shape"
//                         result="effect1_innerShadow_614_1206"
//                       />
//                       <feColorMatrix
//                         in="SourceAlpha"
//                         type="matrix"
//                         values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
//                         result="hardAlpha"
//                       />
//                       <feOffset dx="-2" dy="-2" />
//                       <feGaussianBlur stdDeviation="2" />
//                       <feComposite
//                         in2="hardAlpha"
//                         operator="arithmetic"
//                         k2="-1"
//                         k3="1"
//                       />
//                       <feColorMatrix
//                         type="matrix"
//                         values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.12 0"
//                       />
//                       <feBlend
//                         mode="normal"
//                         in2="effect1_innerShadow_614_1206"
//                         result="effect2_innerShadow_614_1206"
//                       />
//                     </filter>
//                     <filter
//                       id="filter1_ii_614_1206"
//                       x="25.5"
//                       y="23.5001"
//                       width="126"
//                       height="128"
//                       filterUnits="userSpaceOnUse"
//                       color-interpolation-filters="sRGB"
//                     >
//                       <feFlood flood-opacity="0" result="BackgroundImageFix" />
//                       <feBlend
//                         mode="normal"
//                         in="SourceGraphic"
//                         in2="BackgroundImageFix"
//                         result="shape"
//                       />
//                       <feColorMatrix
//                         in="SourceAlpha"
//                         type="matrix"
//                         values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
//                         result="hardAlpha"
//                       />
//                       <feOffset dx="4" dy="4" />
//                       <feGaussianBlur stdDeviation="2" />
//                       <feComposite
//                         in2="hardAlpha"
//                         operator="arithmetic"
//                         k2="-1"
//                         k3="1"
//                       />
//                       <feColorMatrix
//                         type="matrix"
//                         values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
//                       />
//                       <feBlend
//                         mode="normal"
//                         in2="shape"
//                         result="effect1_innerShadow_614_1206"
//                       />
//                       <feColorMatrix
//                         in="SourceAlpha"
//                         type="matrix"
//                         values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
//                         result="hardAlpha"
//                       />
//                       <feOffset dx="-2" dy="-4" />
//                       <feGaussianBlur stdDeviation="2" />
//                       <feComposite
//                         in2="hardAlpha"
//                         operator="arithmetic"
//                         k2="-1"
//                         k3="1"
//                       />
//                       <feColorMatrix
//                         type="matrix"
//                         values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
//                       />
//                       <feBlend
//                         mode="normal"
//                         in2="effect1_innerShadow_614_1206"
//                         result="effect2_innerShadow_614_1206"
//                       />
//                     </filter>
//                   </defs>
//                 </svg>
//               </button>
//             </div>
//           </div>
//         </div>
//       </section>
//       <AudioReceiver
//         selectedGroup={selectedFrequency}
//         //  data={dataToSend}
//       />
//     </div>
//   );
// }

// export default Walkietalkie2;
