import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import io from "socket.io-client";
import axios from "axios";
import { ReactMic } from "react-mic";
import {
  faPlus,
  faMinus,
  faCaretUp,
  faCaretDown,
  faEye,
  faPlay,
  faArrowAltCircleLeft,
  faArrowRotateLeft,
  faArrowRotateRight,
  faBatteryThreeQuarters,
} from "@fortawesome/free-solid-svg-icons";
import AudioPlayer from "react-h5-audio-player";
import { Link } from "react-router-dom";
import AudioReceiver from "./AudioReceiver";
import { useNavigate } from "react-router-dom";

import "react-h5-audio-player/lib/styles.css";
const socket = io("https://api.vcaretechnologies.net");

function WalkieTalkie() {
  const selectedFrequencyFromLocalStorage =
    localStorage.getItem("selectedFrequency");
  const selectedcolorFromLocalStorage = localStorage.getItem("buttonColor");

  const [buttonColor, setButtonColor] = useState(
    selectedcolorFromLocalStorage || "white"
  );
  const [isRecording, setIsRecording] = useState(false);
  const [audioBlob, setAudioBlob] = useState(null);
  // const [selectedFrequency, setSelectedFrequency] = useState("frequency 1");
  const [selectedFrequency, setSelectedFrequency] = useState(
    selectedFrequencyFromLocalStorage || "Frequency 1"
  );
  const [lastAudioIndex, setLastAudioIndex] = useState(-1);
  const [selectedImage, setSelectedImage] = useState(null);
  const [textMessage, setTextMessage] = useState("");
  const [audioMessages, setAudioMessages] = useState([]);
  const [volume, setVolume] = useState(50);
  const [alertShown, setAlertShown] = useState(false);
  const [currentGroup, setCurrentGroup] = useState("");
  const [loading, setLoading] = useState(true);
  const [refreshInterval, setRefreshInterval] = useState(null);
  const [isClicked, setIsClicked] = useState(false);
  const [longPressTimer, setLongPressTimer] = useState(null);
  const [select, setSelect] = useState("");
  const [sessionID, setSessionID] = useState(
    localStorage.getItem("randomSession") || generateRandomString(10)
  );
  const navigate = useNavigate();
  const frequencyOptions = [
    "Frequency 1",
    "Frequency 2",
    "District Officials",
    "Hospital",
    "Fire",
  ];

  function generateRandomString(length) {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters.charAt(randomIndex);
    }
    return result;
  }

  // Set the session ID in localStorage (if it doesn't exist)
  useEffect(() => {
    if (!localStorage.getItem("randomSession")) {
      localStorage.setItem("randomSession", sessionID);
    }
  }, [sessionID]);

  useEffect(() => {
    localStorage.setItem("selectedFrequency", selectedFrequency);
  }, [selectedFrequency]);

  // console.log("sgecdgdc==>",selectedFrequency)

  // Function to toggle the button color
  const toggleButton = () => {
    // Toggle between green and red
    const newColor = buttonColor === "red" ? "white" : "red";
    setButtonColor(newColor);
  };

  const audioPlayerRef = useRef(null);

  // const startRecording = () => {
  //   setIsRecording(true);
  // };

  // const stopRecording = () => {
  //   setIsRecording(false);
  // };
  const startRecording = () => {
    // Set a timeout to start recording after 10 seconds
    const timer = setTimeout(() => {
      setIsRecording(true);
    }, 1000); // 10 seconds in milliseconds
    setLongPressTimer(timer);
  };
  const stopRecording = () => {
    // Clear the long press timer
    if (longPressTimer) {
      clearTimeout(longPressTimer);
    }

    // Stop recording
    setIsRecording(false);
  };
  const onStop = (recordedBlob) => {
    const audioBlob = recordedBlob.blob;
    //console.log("AudioBlob:", audioBlob);
    sendAudio(audioBlob);
  };

  const onData = (recordedBlob) => {
    // Do something with recordedBlob if needed
  };

  const handleGroupChange = (group) => {
    setSelectedFrequency(group);
    localStorage.setItem("selectedFrequency", selectedFrequency);
    // console.log("Selected Frequency in handleGroupChange:", group);
    socket.emit("join-group", group);
    // fetchAudioMessages(group);
    // console.log("Selected Group:", group);
  };
  useEffect(() => {
    //console.log("Selected Frequency Updated: ", selectedFrequency);
    handleGroupChange(selectedFrequency);
  }, [selectedFrequency]);
  // console.log("hello==>", selectedFrequency);

  const sendAudio = async (audioBlob) => {
    try {
      if (audioBlob) {
        // console.log("Selected Frequency2222:", selectedFrequency);
        socket.emit("send-data", {
          data: audioBlob,
          group: selectedFrequency,
          session: sessionID,
          type: 1,
        });
        // console.log("Sending audio with group:", selectedFrequency);
        // console.log("Sending sesssion with id:", sessionID);
      } else {
        console.log("No audio to send");
      }
    } catch (error) {
      console.error("Error sending audio:", error);
    }
  };

  // Handle image selection and upload
  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    setSelectedImage(file);
  };

  const sendImage = async () => {
    try {
      if (selectedImage) {
        const formData = new FormData();
        formData.append("image", selectedImage);

        await axios.post(
          "https://api.vcaretechnologies.net/api/upload-image",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        console.log("Image sent successfully");
      } else {
        console.log("No image to send");
      }
    } catch (error) {
      console.error("Error sending image:", error);
    }
  };

  const fetchAudioMessages = async (selectedFrequency) => {
    // socket.emit('audio-messages',  selectedFrequency);
    // // // Listen for the response
    // socket.on('itemById', (itemData) => {
    //   setAudioMessages(itemData);
    //   //setText(itemData);
    // });
    // try {
    //   const response = await axios.get(
    //     `https://api.vcaretechnologies.net/api/audio-messages?group=${selectedFrequency}`
    //   );
    //   setAudioMessages(response.data.response);
    //   console.log("res====>", response.data.response);
    //   setLoading(false);
    // } catch (error) {
    //   console.log("error===>", error);
    // }
  };

  // useEffect(() => {
  //   //fetchAudioMessages();
  //   if (selectedFrequency !== currentGroup) {
  //     setCurrentGroup(selectedFrequency);

  //     if (audioPlayerRef.current) {
  //       audioPlayerRef.current.audio.current.pause();
  //     }
  //   }

  //   // socket.emit('audio-messages',  selectedFrequency);
  //   // socket.on('itemById', (itemData) => {
  //   //   //setAudioMessages(itemData);
  //   //   setText(itemData);
  //   // });

  //   socket.on("received-audio", (audioPath) => {
  //     //   setAudioMessages((prevMessages) => [
  //     //     ...prevMessages,
  //     //     { audio_url: audioPath },
  //     //   ]);
  //     // });
  //     const newAudioMessage = {
  //       audio_url: audioPath,
  //       group_name: selectedFrequency,
  //     };
  //     setAudioMessages((prevMessages) => {
  //       // Update the last audio index when a new audio message arrives
  //       setLastAudioIndex(prevMessages.length);
  //       return [...prevMessages, newAudioMessage];
  //     });
  //   });

  //   setCurrentGroup(selectedFrequency);

  //   return () => {
  //     socket.off("received-audio");
  //   };
  // }, [selectedFrequency, currentGroup]);

  // useEffect(() => {
  //   if (refreshInterval) {
  //     clearInterval(refreshInterval); // Clear the previous interval
  //   }

  //   // Start a new interval that fetches audio messages every 2 seconds
  //   const newInterval = setInterval(fetchAudioMessages, 1000);

  //   setRefreshInterval(newInterval); // Store the new interval in state

  //   return () => {
  //     if (newInterval) {
  //       clearInterval(newInterval); // Clear the interval when the component unmounts
  //     }
  //   };
  // }, [selectedFrequency]);

  // Function to handle increasing the frequency
  const increaseFrequency = () => {
    const currentIndex = frequencyOptions.indexOf(selectedFrequency);
    if (currentIndex > 0) {
      setSelectedFrequency(frequencyOptions[currentIndex - 1]);
    }
  };

  // Function to handle decreasing the frequency
  const decreaseFrequency = () => {
    const currentIndex = frequencyOptions.indexOf(selectedFrequency);
    if (currentIndex < frequencyOptions.length - 1) {
      setSelectedFrequency(frequencyOptions[currentIndex + 1]);
    }
  };

  const increaseVolume = () => {
    if (volume < 100) {
      setVolume(volume + 10);
    }
  };

  const decreaseVolume = () => {
    if (volume > 0) {
      setVolume(volume - 10);
    }
  };

  useEffect(() => {
    if (alertShown) {
      setAlertShown(false);
    }
  }, [alertShown]);

  const handleFileUpload = async () => {
    // Create an input element
    const inputElement = document.createElement("input");
    inputElement.type = "file";

    // Add an event listener to handle the file selection
    inputElement.addEventListener("change", async (event) => {
      const selectedImage = event.target.files[0]; // Get the selected file

      if (selectedImage) {
        // Create a FormData object to send the file to the server
        const formData = new FormData();
        formData.append("image", selectedImage);
        formData.append("type", "3");
        formData.append("session", sessionID);
        formData.append("group", selectedFrequency);

        // Send the file to your server using the Fetch API with Axios
        try {
          await axios.post("http://localhost:5000/api/upload-image", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
          localStorage.setItem("buttonColor", buttonColor);
          navigate("/chat");
          console.log("Image sent successfully");
        } catch (error) {
          console.error("Error sending the image:", error);
        }
      } else {
        console.log("No image to send");
      }
    });

    // Trigger the file input dialog
    inputElement.click();
  };
  const handleThemeChange = (event) => {
    const selectedTheme = event.target.value;

    if (selectedTheme === "Theme 2") {
      navigate("/walkie"); // Use the navigate function to redirect
    }
  };

  const handleChatButtonClick = () => {
    // Set the path and button color in localStorage
    // Set the path
    localStorage.setItem("buttonColor", buttonColor); // Set the button color

    // Redirect to the chat page
    navigate("/chat");
  };

  return (
    <div>
      <section className="walkie_talkie_section d-flex justify-content-center">
        {/*<div>
          <select
            class="form-control transparent-background"
            onChange={handleThemeChange}
          >
            <option value="Theme 1">Theme 1 </option>
            <option value="Theme 2">Theme 2</option>
          </select>
        </div>*/}
        {/* <div className="container-fluid"> */}
        <div className="main_mobile_part">
          <div className="mobile_bg_box">
            <img src="images/mobile_bg.png" alt="Mobile Background" />
            <button className="walkie_top_btn" onClick={toggleButton}>
              <img
                src="images/walkie_top_img.png"
                alt="Walkie Top Image"
                className="walkie_top_img"
              />
            </button>
            <button
              className="press_line"
              style={{ backgroundColor: buttonColor }}
            ></button>
            <div className="mobile_display">
              <div className="select_box">
                <select
                  className="form-control transparent-background"
                  value={selectedFrequency}
                  onChange={(e) => handleGroupChange(e.target.value)}
                  // onChange={handleGroupChange}
                >
                  {/* Map through frequencyOptions and generate dropdown options */}
                  {frequencyOptions.map((frequency) => (
                    <option key={frequency} value={frequency}>
                      {frequency}
                    </option>
                  ))}
                </select>
                <FontAwesomeIcon
                  icon={faBatteryThreeQuarters}
                  className="battery_icon"
                />
                <div className="pulse_box">
                  {/* <video  src="images/sound.mp4"autoPlay loop muted  className="video_box"/> */}
                  <ReactMic
                    record={isRecording}
                    onData={onData}
                    onStop={onStop}
                    className="sound-wave"
                    mimeType="audio/webm"
                  />
                  <button className="btn play_button">
                    <FontAwesomeIcon icon={faPlay} />
                  </button>
                  {/*<a href="/view" className="btn view_button">
                    <FontAwesomeIcon icon={faArrowRotateRight} />
                  </a>*/}
                </div>
              </div>
            </div>
            <div className="mobile_body">
              <span className="chat_btn">
                {/* <Link to="/chat"> */}
                <button onClick={handleChatButtonClick}>Chat</button>
                {/* </Link> */}
              </span>
              <span className="upload_btn">
                <button onClick={handleFileUpload}>Camera</button>
              </span>

              <div className="ch_box">
                <button onClick={increaseFrequency}>
                  <FontAwesomeIcon icon={faCaretUp} />
                </button>
                <span>CH</span>
                <button onClick={decreaseFrequency}>
                  <FontAwesomeIcon icon={faCaretDown} />
                </button>
              </div>
              <div className="vol_box">
                <button className="blue animated" onClick={increaseVolume}>
                  {" "}
                  <FontAwesomeIcon icon={faPlus} />
                </button>
                <span>Vol</span>
                <button onClick={decreaseVolume}>
                  <FontAwesomeIcon icon={faMinus} />
                </button>
              </div>
            </div>
            <div className="voice_btn">
              <button
                onMouseDown={startRecording}
                onMouseUp={stopRecording}
                onTouchStart={startRecording}
                onTouchEnd={stopRecording}
                disabled={buttonColor === "white"}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="101"
                  height="102"
                  viewBox="0 0 101 102"
                  fill="none"
                >
                  <circle
                    cx="50.3797"
                    cy="51.1839"
                    r="50.3797"
                    fill="#C9430F"
                  />
                  <ellipse
                    cx="50.3281"
                    cy="51.0953"
                    rx="47.5"
                    ry="47"
                    fill="#FF6326"
                  />
                  <path
                    d="M16.6532 69.8828C15.7325 70.4144 14.5506 70.101 14.0657 69.1548C11.5943 64.3326 10.1652 59.0359 9.88089 53.6103C9.59654 48.1846 10.4641 42.7676 12.4179 37.7134C12.8013 36.7218 13.944 36.2865 14.9152 36.7189C15.8864 37.1514 16.3179 38.2876 15.94 39.2814C14.2301 43.7787 13.4731 48.5899 13.7256 53.4088C13.9782 58.2276 15.234 62.9334 17.4046 67.2273C17.8842 68.1761 17.574 69.3513 16.6532 69.8828Z"
                    fill="white"
                  />
                  <path
                    d="M83.6485 70.8328C84.617 71.392 85.8603 71.0623 86.3704 70.067C88.9702 64.9942 90.4735 59.4225 90.7726 53.7149C91.0717 48.0074 90.1591 42.309 88.1038 36.9923C87.7006 35.9492 86.4985 35.4913 85.4768 35.9461C84.4551 36.401 84.0013 37.5963 84.3987 38.6417C86.1975 43.3726 86.9938 48.4338 86.7282 53.503C86.4625 58.5722 85.1414 63.5224 82.8581 68.0394C82.3535 69.0375 82.6799 70.2737 83.6485 70.8328Z"
                    fill="white"
                  />
                  <path
                    d="M50.3281 55.8453C54.1695 55.8453 57.2827 52.656 57.2827 48.7203V39.2203C57.2827 35.2846 54.1695 32.0953 50.3281 32.0953C46.4868 32.0953 43.3736 35.2846 43.3736 39.2203V48.7203C43.3736 52.656 46.4868 55.8453 50.3281 55.8453ZM63.8281 48.6355C63.8281 48.4489 63.6809 48.2962 63.5009 48.2962H61.0463C60.8663 48.2962 60.719 48.4489 60.719 48.6355C60.719 54.5857 56.0677 59.4078 50.3281 59.4078C44.5886 59.4078 39.9372 54.5857 39.9372 48.6355C39.9372 48.4489 39.7899 48.2962 39.6099 48.2962H37.1554C36.9754 48.2962 36.8281 48.4489 36.8281 48.6355C36.8281 55.7902 42.0072 61.6938 48.6918 62.5293V66.8721H42.7477C42.1872 66.8721 41.7372 67.4786 41.7372 68.2293V69.7561C41.7372 69.9427 41.8518 70.0953 41.9909 70.0953H58.6654C58.8045 70.0953 58.919 69.9427 58.919 69.7561V68.2293C58.919 67.4786 58.469 66.8721 57.9086 66.8721H51.8009V62.5505C58.5631 61.7871 63.8281 55.8496 63.8281 48.6355Z"
                    fill="white"
                  />
                </svg>
              </button>
              {/* {audioMessages.map((message, index) => {
            const audioUrl = `https://api.vcaretechnologies.net${message.audio_url}`;

            console.log("Audio URL====:", audioUrl);
            if (message.group_name === selectedFrequency) {
              const isLastAudio = index === lastAudioIndex;
              return (
                <div key={index}>
                  <AudioPlayer
                    ref={audioPlayerRef}
                    src={audioUrl}
                    controls
                    autoPlayAfterSrcChange={false}
                    autoPlay={isLastAudio}
                  />
                </div>
              );
            }
            return null;
          })} */}
            </div>
          </div>
        </div>
        {/* </div> */}
      </section>
      <AudioReceiver
        selectedGroup={selectedFrequency}
        //  data={dataToSend}
      />
      <div className="disclaimer">
        <p>
          This is for educational purpose only. It is a dummy platform, we do
          not use any live frequency
        </p>
      </div>
    </div>
  );
}

export default WalkieTalkie;
