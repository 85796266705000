// import React, { useState, useEffect } from "react";
// import { ReactMic } from "react-mic";
// import AudioPlayer from "react-h5-audio-player";
// import "react-h5-audio-player/lib/styles.css";
// import io from "socket.io-client";
// import axios from "axios";
// import ViewRecording from "./ViewRecording";

// const socket = io("https://api.vcaretechnologies.net"); // Replace with your server URL

// const AudioRecorder = () => {
//   const [isRecording, setIsRecording] = useState(false);
//   const [audioBlob, setAudioBlob] = useState(null);
//   const [selectedFrequency, setselectedFrequency] = useState("Frequency 1");
//   const [audioMessages, setAudioMessages] = useState([]);
//   const [selectedImage, setSelectedImage] = useState(null);
//   const [textMessage, setTextMessage] = useState("");

//   const startRecording = () => {
//     setIsRecording(true);
//   };

//   const stopRecording = () => {
//     setIsRecording(false);
//   };

//   const onData = (recordedBlob) => {
//     // Do something with recordedBlob if needed
//   };

//   // const onStop = (recordedBlob) => {
//   //   setAudioBlob(recordedBlob.blob);
//   // };

//   // const sendAudio = () => {
//   //   if (audioBlob) {
//   //     socket.emit("send-audio", { audioBlob, group: selectedFrequency });
//   //   }
//   // };
//   // const sendAudio = () => {
//   //   if (audioBlob) {
//   //     console.log("Sending audio with group:", selectedFrequency);
//   //     socket.emit("send-audio", { audioBlob, group: selectedFrequency });
//   //   } else {
//   //     console.log("No audio to send");
//   //   }
//   // };

    


 




//   const onStop = (recordedBlob) => {
//     const audioBlob = recordedBlob.blob;
//     sendAudio(audioBlob);
//   };
  
//   const sendAudio = async (audioBlob) => {
//     try {
//       if (audioBlob) {
//         socket.emit("send-audio", { audioBlob, group: selectedFrequency });
//         console.log("Sending audio with group:", selectedFrequency);
//       } else {
//         console.log("No audio to send");
//       }
//     } catch (error) {
//       console.error("Error sending audio:", error);
//     }
//   };

//   const handleTextChange = (e) => {
//     setTextMessage(e.target.value);
//   };

//   const sendTextMessage = () => {
//     socket.emit("send-text", { sender: "YourName", messageText: textMessage });
//     setTextMessage(""); // Clear the input field after sending
//   };
//   console.log("text==>",textMessage)

//     // Handle image selection and upload
//     const handleImageUpload = (e) => {
//       const file = e.target.files[0];
//       setSelectedImage(file);
//     };


//     const sendImage = async () => {
//       try {
//         if (selectedImage) {
//           const formData = new FormData();
//           formData.append("image", selectedImage);
  
//           await axios.post("https://api.vcaretechnologies.net/api/upload-image", formData, {
//             headers: {
//               "Content-Type": "multipart/form-data",
//             },
//           });
  
//           console.log("Image sent successfully");
//         } else {
//           console.log("No image to send");
//         }
//       } catch (error) {
//         console.error("Error sending image:", error);
//       }
//     };
  



//   useEffect(() => {
//     handleGroupChange(selectedFrequency);
//   }, [selectedFrequency]);

//   const handleGroupChange = (group) => {
//     setselectedFrequency(group);
//     socket.emit("join-group", group);
//     fetchAudioMessages(group);
//     console.log("Selected Group:", group);
//   };

//   const fetchAudioMessages = async (group) => {
//     socket.emit('audio-messages',  group);

//     // // Listen for the response
//     socket.on('itemById', (itemData) => {
//       setAudioMessages(itemData);
//       console.log("itemdata------>",itemData)
//       //setText(itemData);
//     });
//     // try {
//     //   const response = await axios.get(
//     //     `https://api.vcaretechnologies.net/api/audio-messages?group=${group}`
//     //   );
//     //   setAudioMessages(response.data);
//     //   console.log("audiomessage===>", textMessage);
//     // } catch (error) {
//     //   console.error("Error fetching audio messages:", error);
//     // }
//   };

//   return (
//     <>
//     <div className="mt-5 mx-3">
//       <button
//         className="btn btn-primary mx-2"
//         onClick={() => handleGroupChange("Frequency 1")}
//       >
//         Frequency 1
//       </button>
//       <button
//         className="btn btn-secondary mx-2"
//         onClick={() => handleGroupChange("Frequency 2")}
//       >
//        Frequency 2
//       </button>
//       <button
//         className="btn btn-info mx-2"
//         onClick={() => handleGroupChange("Frequency 3")}
//       >
//         Frequency 3
//       </button>
//       <button
//         className="btn btn-warning"
//         onClick={() => handleGroupChange("Frequency 4")}
//       >
//        Frequency 4
//       </button>

//       <div className="mt-4 mb-3">
//         <textarea
//           rows="4"
//           cols="50"
//           value={textMessage}
//           onChange={handleTextChange}
//           placeholder="Type your message..."
//         />
//         <button onClick={sendTextMessage} className="mx-3">Send Text</button>
//       </div>

//       <input type="file" accept="image/*"  className="form-control mb-3 w-25"  onChange={handleImageUpload}  />
//         <button onClick={sendImage} className="mb-5">Send Image</button>

//       <div>
//         {/* <ReactMic
//           record={isRecording}
//           onData={onData}
//           onStop={onStop}
//           className="sound-wave"
//           mimeType="audio/webm"
//         /> */}
//         {/* <button onClick={startRecording} disabled={isRecording}>
//           Start Recording
//         </button>
//         <button onClick={stopRecording} disabled={!isRecording}>
//           Stop Recording
//         </button>
//         <button onClick={sendAudio} disabled={!audioBlob}>
//           Send Audio
//         </button> */}
//         {/* <button
//           onMouseDown={startRecording}
//           onMouseUp={stopRecording}
//           onTouchStart={startRecording}
//           onTouchEnd={stopRecording}
//         >
//           Hold to Recordecord
//         </button> */}
//         {/* {audioBlob && (
//           <div>
//             <AudioPlayer
//               src={URL.createObjectURL(audioBlob)}
//               autoPlay
//               controls
//             />
//           </div>
//         )} */}
//         <ViewRecording
//           selectedFrequency={selectedFrequency}
//           //  data={dataToSend}
//         />
//       </div>
//       </div>
//     </>
//   );
// };

// export default AudioRecorder;
