import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import WalkieTalkie from "./WalkieTalkie";
import Chat from "./Chat";
import Walkietalkie2 from './Walkietalkie2';
import AudioRecorder from "./AudioRecorder";
import "./App.css";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/chat" element={<Chat />} />
        <Route path="/view" element={<AudioRecorder />} />
        <Route path="/walkie" element={<Walkietalkie2 />} />
        <Route path="/" element={<WalkieTalkie />} />
      </Routes>
    </Router>
  );
}

export default App;
