import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleLeft,
  faBackward,
  faCamera,
  faMicrophone,
  faMicrophoneLines,
  faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";
import { ReactMic } from "react-mic";
import { Link } from "react-router-dom";
import io from "socket.io-client";
import axios from "axios";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";

const socket = io("http://api.vcaretechnologies.net");

function Chat() {
  const selectedFrequencyFromLocalStorage =
    localStorage.getItem("selectedFrequency");
  const [isRecording, setIsRecording] = useState(false);
  const [textMessage, setTextMessage] = useState("");
  const [text, setText] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageMessages, setImageMessages] = useState([]);
  const [selectedFrequency, setSelectedFrequency] = useState(
    selectedFrequencyFromLocalStorage || "Frequency 1"
  );
  const [currentGroup, setCurrentGroup] = useState("");
  const [audioMessages, setAudioMessages] = useState([]);
  const [lastAudioIndex, setLastAudioIndex] = useState(-1);
  const [refreshInterval, setRefreshInterval] = useState(null);
  const [sessionID] = useState(localStorage.getItem("randomSession"));

  const audioPlayerRef = useRef(null);

  useEffect(() => {
    if (!localStorage.getItem("randomSession")) {
      localStorage.setItem("randomSession", sessionID);
    }
  }, [sessionID]);

  useEffect(() => {
    const storedFrequency = localStorage.getItem("selectedFrequency");
    if (storedFrequency) {
      setSelectedFrequency(storedFrequency);
    }
  }, []);

  const startRecording = () => {
    setIsRecording(true);
  };

  const stopRecording = () => {
    setIsRecording(false);
  };

  const handleTextChange = (e) => {
    setTextMessage(e.target.value);
  };
  const onData = (recordedBlob) => {
    // Do something with recordedBlob if needed
  };
  const onStop = (recordedBlob) => {
    const audioBlob = recordedBlob.blob;
    //console.log("AudioBlob:", audioBlob);
    sendAudio(audioBlob);
  };

  const sendAudio = async (audioBlob) => {
    try {
      if (audioBlob) {
        console.log("Selected Frequency2222:", selectedFrequency);
        socket.emit("send-data", {
          data: audioBlob,
          group: selectedFrequency,
          session: sessionID,
          type: 1,
        });
        console.log("Sending audio with group:", selectedFrequency);
        console.log("Sending sesssion with id:", sessionID);
      } else {
        console.log("No audio to send");
      }
    } catch (error) {
      console.error("Error sending audio:", error);
    }
  };

  const sendTextMessage = () => {
    socket.emit("send-data", {
      sender: "YourName",
      data: textMessage,
      type: 2,
      group: selectedFrequency,
      session: sessionID,
    });
    setTextMessage(""); // Clear the input field after sending
  };

  const fetchAudioMessages = async () => {
    try {
      console.log("Test Data", selectedFrequency);
      socket.emit("audio-messages", selectedFrequency);

      // // Listen for the response
      socket.on("all", (itemData) => {
        //setAudioMessages(itemData);
        console.log("All Data", itemData);
        setText(itemData);
      });
    } catch (error) {
      console.log("error===>", error);
    }

    // console.log(text);
    // try {
    //   const response = await axios.get(
    //     `https://api.vcaretechnologies.net/api/audio-messages?group=${selectedFrequency}`
    //   );
    //   setAudioMessages(response?.data?.response);
    //   setText(response?.data?.response);
    //   console.log("res====>", response);

    //   setLoading(false);
    // } catch (error) {
    //   // console.log("error===>", error);
    // }
  };
  //console.log("text==>", text);

  useEffect(() => {
    try {
      console.log("usefecttsv i snot working");

      //fetchAudioMessages();
      if (selectedFrequency !== currentGroup) {
        setCurrentGroup(selectedFrequency);

        if (audioPlayerRef.current) {
          audioPlayerRef.current.audio.current.pause();
        }
      }

      socket.emit("audio-messages", selectedFrequency);
      socket.on("audio-messages", (itemData) => {
        //setAudioMessages(itemData);
        setText(itemData);
      });
      console.log("asdf==>", text);

      socket.on("text", (itemData) => {
        //setAudioMessages(itemData);
        console.log("test===>", text);
        setText(itemData);
      });

      socket.on("image", (itemData) => {
        //setAudioMessages(itemData);
        setText(itemData);
      });

      // socket.on('all', (itemData) => {
      //   console.log("All Data",itemData);
      //   //setAudioMessages(itemData);
      //   setText(itemData);
      // });

      socket.on("all", (itemData) => {
        try {
          console.log("Received 'all' event:", itemData);
          setText(itemData);
        } catch (error) {
          console.error("Error handling 'all' event:", error);
        }
      });

      // socket.on("received-audio", (audioPath) => {
      socket.on("all", (data) => {
        console.log("asdf-->", data);

        //   setAudioMessages((prevMessages) => [
        //     ...prevMessages,
        //     { audio_url: audioPath },
        //   ]);
        // });
        // const newAudioMessage = {
        //   audio_url: audioPath,
        //   group_name: selectedFrequency,
        // };
        setAudioMessages((prevMessages) => {
          // Update the last audio index when a new audio message arrives
          // setLastAudioIndex(prevMessages.length-1);
          const lastOuterIndex = prevMessages.length - 1;
          const innerArray = prevMessages[lastOuterIndex] || [];
        
          
          innerArray.forEach((message, index) => {
            if (message.type === 1) {
              console.log(`Index of type 1 message: ${lastOuterIndex}-${index}`);
              setLastAudioIndex(index)
            }
          });
        
          console.log("setLastAudioIndex==>", innerArray);
          return [...prevMessages, data];
        });
      });

      // Listen for image messages from the server
      socket.on("received-image", (imageUrl) => {
        setImageMessages((prevImages) => [...prevImages, imageUrl]);
      });

      socket.on("received-text", (textMessage) => {
        setText((prevMessages) => [...prevMessages, textMessage]);
      });
      console.log("testes===>", text);
      setCurrentGroup(selectedFrequency);
    } catch (error) {
      console.log("usefecttsv i snot working", error);
    }

    return () => {
      socket.off("received-audio");
      socket.off("received-text");
      socket.off("received-image");
    };
  }, [selectedFrequency, currentGroup]);
  const formatTimestamp = (timestamp) => {
    return new Date(timestamp).toLocaleTimeString(); // Format the timestamp
  };

  const handleFileUpload = async () => {
    // Create an input element
    const inputElement = document.createElement("input");
    inputElement.type = "file";

    // Add an event listener to handle the file selection
    inputElement.addEventListener("change", async (event) => {
      const selectedImage = event.target.files[0]; // Get the selected file

      if (selectedImage) {
        // Create a FormData object to send the file to the server
        const formData = new FormData();
        formData.append("image", selectedImage);
        formData.append("type", "3");
        formData.append("session", sessionID);
        formData.append("group", selectedFrequency);

        // Send the file to your server using the Fetch API with Axios
        try {
          await axios.post(
            "https://api.vcaretechnologies.net/api/upload-image",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          console.log("Image sent successfully");
        } catch (error) {
          console.error("Error sending the image:", error);
        }
      } else {
        console.log("No image to send");
      }
    });

    // Trigger the file input dialog
    inputElement.click();
  };

  // useEffect(() => {
  //   if (refreshInterval) {
  //     clearInterval(refreshInterval); // Clear the previous interval
  //   }

  //   // Start a new interval that fetches audio messages every 2 seconds
  //   const newInterval = setInterval(fetchAudioMessages, 2000);

  //   setRefreshInterval(newInterval); // Store the new interval in state

  //   return () => {
  //     if (newInterval) {
  //       clearInterval(newInterval); // Clear the interval when the component unmounts
  //     }
  //   };
  // }, [selectedFrequency]);
  console.log("tesxt==>", text);
  console.log("tesxt2==>", selectedFrequency);
  console.log("tesxt3==>", sessionID);
  console.log("tesxt4==>", text.group_name);

  return (
    <section class="main_chat_area">
      <div class="container">
        <div class="chat_heading">
          {/* <a href="/" class="back_arrow" > */}
          <Link to="/" className="back_arrow">
            <FontAwesomeIcon icon={faAngleDoubleLeft} />
            <span>Chat</span>
          </Link>
          <h6 className="frequency">{selectedFrequency}</h6>
        </div>
        <div class="chat_area">
          <div class="main_chat_box">
            {text.map((message, index) => {
              if (message.type == 1) {
                // Display audio content

                // if (
                //   text.group_name === selectedFrequency &&
                //   text.session === sessionID
                // ) {
                const isLastAudio = index === lastAudioIndex;
                console.log("isLastAudio===>", isLastAudio);
                console.log("index===>", index);
                console.log("lastAudioIndex===>", lastAudioIndex);
                const audioUrl = `https://api.vcaretechnologies.net${message.url}`;

                return (
                  <div key={index}>
                    {/* <AudioPlayer
              ref={audioPlayerRef}
              src={audioUrl}
              controls
              autoPlayAfterSrcChange={false}
              // autoPlay={isLastAudio}
            /> */}
                    <audio controls autoPlay={isLastAudio}>
                      <source src={audioUrl} type="audio/mpeg" />
                    </audio>
                  </div>
                );
                // }
                // return null;
              } else if (message.type == 2) {
                // Display text content

                return <p key={index}>{message.url}</p>;
              } else if (message.type == 3) {
                // Display image content
                return (
                  <div key={index}>
                    <img
                      src={`https://api.vcaretechnologies.net${message.url}`}
                      alt={`Image ${index}`}
                      style={{
                        maxWidth: "200px",
                        maxHeight: "200px",
                        marginBottom: "10px",
                        display: "block",
                      }}
                    />
                  </div>
                );
              } else {
                return null;
              }
            })}
          </div>
        </div>
        <ReactMic
          record={isRecording}
          onData={onData}
          onStop={onStop}
          className="wave"
          mimeType="audio/webm"
        />
        <div class="chat_footer">
          <div class="row w-100 align-items-center justify-content-between">
            <button className="upload_btn col-2 btn" onClick={handleFileUpload}>
              <FontAwesomeIcon icon={faCamera} />
            </button>
            <div class="main_text_box col-8">
              <button
                class="speaker_img btn"
                onMouseDown={startRecording}
                onMouseUp={stopRecording}
                onTouchStart={startRecording}
                onTouchEnd={stopRecording}
              >
                <FontAwesomeIcon icon={faMicrophoneLines} />
              </button>
              <input
                type="text"
                onChange={handleTextChange}
                value={textMessage}
                className="send_msg_input form-control"
              />
            </div>
            <button class="send_button col-2">
              <FontAwesomeIcon icon={faPaperPlane} onClick={sendTextMessage} />
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Chat;
