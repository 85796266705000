import React, { useState, useEffect, useRef } from "react";
import io from "socket.io-client";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import axios from "axios";

const socket = io("https://api.vcaretechnologies.net");
// ("https://api.vcaretechnologies.net"); // Replace with your server URL

const AudioReceiver = ({ selectedGroup }) => {
  const [audioMessages, setAudioMessages] = useState([]);
  const [audioUrl, setAudioUrl] = useState([]);
  const [autoPlay, setAutoPlay] = useState(true);
  const [currentGroup, setCurrentGroup] = useState("");
  const [loading, setLoading] = useState(true);
  const [refreshInterval, setRefreshInterval] = useState(null);
  const [lastAudioIndex, setLastAudioIndex] = useState(-1);
  const [textMessages, setTextMessages] = useState([]);
  const [imageMessages, setImageMessages] = useState([]);
  const [sessionID, setSessionID] = useState(
    localStorage.getItem("randomSession")
  );
  const [selectedFrequency, setselectedFrequency] = useState(
    localStorage.getItem("selectedFrequency")
  );
  const audioPlayerRef = useRef(null);

  useEffect(() => {
    const storedFrequency = localStorage.getItem("selectedFrequency");
    if (storedFrequency) {
      setselectedFrequency(storedFrequency);
    }
  }, []);
  // console.log("sejdfhfh===>", selectedGroup);

  const fetchAudioMessages = async () => {
    // socket.emit('audio-messages',  selectedFrequency);
    // // // Listen for the response
    // socket.on('itemById', (itemData) => {
    //   setAudioMessages(itemData);
    //   //setText(itemData);
    // });
    // try {
    //   const response = await axios.get(
    //     `https://api.vcaretechnologies.net/api/audio-messages?group=${selectedGroup}`
    //   );
    //   setAudioMessages(response?.data?.response);
    //   // console.log("res====>", response);
    //   setLoading(false);
    // } catch (error) {
    //   console.log("error===>", error);
    // }
  };

  useEffect(() => {
    //fetchAudioMessages();
    if (selectedGroup !== currentGroup) {
      setCurrentGroup(selectedGroup);

      if (audioPlayerRef.current) {
        audioPlayerRef.current.audio.current.pause();
      }
    }

    // socket.emit('audio-messages',  selectedFrequency);
    // socket.on('itemById', (itemData) => {
    //   //setAudioMessages(itemData);
    //   setText(itemData);
    // });
    socket.on("last-audio", (datas) => {
      //   setAudioMessages((prevMessages) => [
      //     ...prevMessages,
      //     { audio_url: audioPath },
      //   ]);
      // });
      //console.log("audioMessages===--->", audioPath);
      const newAudioMessage = {
        // url: audioPath,
        // group_name: selectedGroup,
        // session: sessionID,
      };
      setAudioMessages((prevMessages) => {
        // Update the last audio index when a new audio message arrives
        setLastAudioIndex(prevMessages.length);
        return [...prevMessages, datas];
      });
      // console.log("newAudioMessage==>", datas[0].url);
      var audiorl;
      if (datas[0].session == sessionID) {
        // console.log("hyyy");
      } else {
        // console.log("hello==>");
        audiorl = `https://api.vcaretechnologies.net${datas[0].url}`;
        setAudioUrl(audiorl);
        setAutoPlay(true);
      }
      // console.log("audioUrl:", audioUrl);
      // console.log("audioMessages===--->", audioMessages);
    });

    // Listen for image messages from the server
    socket.on("received-image", (imageUrl) => {
      setImageMessages((prevImages) => [...prevImages, imageUrl]);
    });

    socket.on("received-text", (textMessage) => {
      setTextMessages((prevMessages) => [...prevMessages, textMessage]);
    });
    setCurrentGroup(selectedGroup);

    return () => {
      socket.off("received-audio");
      socket.off("received-text");
      socket.off("received-image");
    };
  }, [selectedGroup, currentGroup, sessionID, autoPlay]);

  useEffect(() => {
    if (audioUrl.length > 0 && autoPlay) {
      // Use a timeout to ensure the state has been updated before playing
      const timeoutId = setTimeout(() => {
        audioPlayerRef.current.audio.current.play();
      }, 100);

      return () => clearTimeout(timeoutId);
    }
  }, [audioUrl, autoPlay]);


  // useEffect(() => {
  //   if (refreshInterval) {
  //     clearInterval(refreshInterval); // Clear the previous interval
  //   }

  //   // Start a new interval that fetches audio messages every 2 seconds
  //   const newInterval = setInterval(fetchAudioMessages, 1000);

  //   setRefreshInterval(newInterval); // Store the new interval in state

  //   return () => {
  //     if (newInterval) {
  //       clearInterval(newInterval); // Clear the interval when the component unmounts
  //     }
  //   };
  // }, [selectedGroup, sessionID]);

  //   return (
  //     <div>
  //       {audioMessages.map((message, index) => (

  //         <div key={index}>
  //           {console.log("Audio src:", `/audio/${message.audio_url}`)}
  //           <AudioPlayer src={`/audio/${message.audio_url}`} autoPlay controls />
  //         </div>

  //       ))}
  //     </div>

  //   );
  // };

  // // Function to handle group change
  // const handleGroupChange = (group) => {
  //   // setselectedFrequency(group);
  //   // Clear the audio messages when changing groups
  //   setAudioMessages([]);
  //   console.log("Selected Group:", group);
  //   socket.emit("join-group", group);
  // };

  // console.log("audioUrl==>:", audioUrl);

  return (
    <div>
      {/* <p>Selected Group: {selectedFrequency}</p> */}
      {textMessages.map((message, index) => (
        <div key={index}>
          <p>
            {message.sender}: {message.message_text}
          </p>
        </div>
      ))}

      {/* {audioMessages.map((message, index) => {
        // const audioUrl= `https://api.vcaretechnologies.net${message.audio_url}`;
        console.log("message.group_name====:", message.group_name);
        console.log("selectedFrequency====:", selectedGroup);
        
        if (message.group_name === selectedGroup) {
          const isLastAudio = index === lastAudioIndex;
          var audioUrl;
          console.log("hyy==>",message.session)
          console.log("sessionID==>",sessionID)
          if (message.session == sessionID) {
            audioUrl = `https://api.vcaretechnologies.net${message.url}`;
            // console.log("hyy==>",message.session)
            // console.log("sessionID==>",sessionID)
          } else {
            //  console.log("not hyy")
            //  shouldAutoPlay = true
            audioUrl = `https://api.vcaretechnologies.net${message.url}`;
          }
          console.log("audioMessages===--->",audioUrl);
          console.log("audioMessages===--->", isLastAudio);
          return (
            <div key={index}>
              <AudioPlayer
                ref={audioPlayerRef}
                src={audioUrl}
                controls
                autoPlayAfterSrcChange={false}
                autoPlay={isLastAudio}
                // muted={true}
                className="hidden-audio-player"
              />
            </div>
          );
        }
        return null;
      })} */}
      {/* {audioUrl.length > 0 && ( */}
        <div>
          <AudioPlayer
            ref={audioPlayerRef}
            src={audioUrl}
            controls
            autoPlayAfterSrcChange={false}
            autoPlay={true}
            className="hidden-audio-player"
          />
        </div>
       {/* )} */}

      {imageMessages?.map((imageUrl, index) => (
        <div key={index}>
          {/* {console.log(`Image URL ${index}:`, imageUrl.image_url)} */}
          <img
            src={`https://api.vcaretechnologies.net${imageUrl.url}`}
            alt={`Image ${index}`}
            style={{
              maxWidth: "200px", // Adjust the maximum width as needed
              maxHeight: "200px", // Adjust the maximum height as needed
              marginBottom: "10px",
              display: "block",
            }}
          />
        </div>
      ))}
    </div>
  );
};

export default AudioReceiver;
